<template>
  <div id="trending-pairs" class="hot-pairs">
    <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 class="h4 mb-0 font-size-125 font-family-krona">Trending</h2>
          </div>
          <div>
            <a href="">More<span class="ml-2"><font-awesome-icon :icon="['fas', 'chevron-right']"/></span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body p-0">
        <table class="table mb-0">
          <tbody>
            <template v-for="(token, index) in tokenList" v-bind:key="index">
              <tr class="tr-clickable" v-if="index < 5" @click="goToDetail(token)" >
                <td class="white-space-nowrap px-2" :class="[{'border-0': index == 0}]">
                  <div class="mr-2 d-flex align-items-center">
                    <span class="mr-2">
                      <img class="float-left rounded-circle" width="20" :src="token.item.thumb"/>
                    </span>
                    <div style="line-height:1.2">
                      <span class="float-left w-100">{{ token.item.symbol }}</span>
                      <small class="float-left text-muted font-size-075 text-ellipsis w-75">{{ token.item.name }}</small>
                    </div>
                  </div>
                </td>
                <td class="font-size-080 px-2" :class="[{'border-0': index == 0}]"><span>{{token.item.data.price}}</span></td>
                <td :class="[{'border-0': index == 0}]" class="text-right px-2"><span class="h6 mb-0 ml-2" :class="[{'text-danger':token.item.data.price_change_percentage_24h.usd < 0},{'text-success':token.item.data.price_change_percentage_24h.usd >= 0}]">{{$fixPrice(token.item.data.price_change_percentage_24h.usd, 2)}}%</span></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      tokenList: '',
    };
  },
  created() {
    axios.get(`/v2/tokens/trending`)
      .then(async (response)=> {
        this.tokenList = response.data.body;
        console.log(this.tokenList);
        this.$emit('widgetLoaded');
      }); 
  },
  methods: {
    goToDetail(crypto){
      this.$router.push({ name: 'crypto-detail', params: { address: crypto.mainToken.address, chain: 'bsc' }, query: { symbol: crypto.mainToken.symbol}});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
